import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap, delay, switchMap } from 'rxjs/operators';

/**
 * A CacheOptions object tells how long a item should be in the cache.
 */
interface CacheOptions {
    expires?: number;
    maxAge?: number;
    tags?: string[];
}

/**
 * A StorageObject is the value-to-store plus a CacheOptions object.
 */
interface StorageObject {
    value: unknown;
    options: CacheOptions;
}

/**
 * Use this service to set items into a key-value cache store with support for item expiry.
 * This service is an adaptop of ng2-cache by Olivier.
 */
@Injectable({ providedIn: 'root' })
export class CacheService {
    // Default prefix. This could be removed when all brands have their own domain but
    // for now it's handy for local development.
    public prefix = 'orca-cache';

    // By default, items don't expire
    private defaultOptions: CacheOptions = {
        expires: Number.MAX_VALUE,
        maxAge: Number.MAX_VALUE
    };

    private inProgress: string[] = [];

    constructor() {}

    /**
   * An easy function to use in default use-cases of the cache service. Use this when:
   *  - you have an observable data source
   *  - you want to cache the data from the data source
   *  - you want to prevent the observable from being triggered again if the prev result is in cache
   * @param key the key to check the cache for (and to use when storing)
   * @param obs the observable data source
   * @param options optional options for storing the data in cache
   */
    public serveFromCacheOr<T>(
        key: string,
        obs: Observable<T>,
        options?: CacheOptions,
        tries = 1
    ): Observable<T> {
    // Is the item with this key already being fetched?
        if (this.isAlreadyInProgress(key)) {
            // Return self with a slight delay
            const delay$ = of(true).pipe(delay(250 * tries));
            return delay$.pipe(
                switchMap(() => this.serveFromCacheOr(key, obs, options, tries + 1))
            );
        }

        const existsInCache = this.exists(key);
        // If the item is not in the cache...
        if (!existsInCache) {
            this.inProgress.push(key);
            // ..return the observable data source and use the RxJS Tap operator to
            // store the result of the observable in the cache
            return obs.pipe(
                tap(res => {
                    this.set(key, res, options);
                    let index = null;
                    while (index !== -1) {
                        index = this.inProgress.indexOf(key); // Set to -1 if not found
                        this.inProgress.splice(index, 1).length > 0;
                    }
                })
            );
        } else {
            // ..otherwise return the cached value as an observable using the RxJS Of operator
            return of(this.get(key) as T);
        }
    }

    /**
   * Checks if an object is in cache
   * @param key
   */
    public exists(key: string): boolean {
        return !!this.get(key);
    }

    /**
   * Saves an object to cache
   * @param key
   * @param value
   * @param options
   */
    public set(key: string, value: unknown, options?: CacheOptions) {
        options = options ? options : this.defaultOptions;
        const storageKey = this.toStorageKey(key);
        const storageValue = this.toStorageValue(value, options);
        for (let attempt = 0; attempt < 2; attempt++) {
            try {
                localStorage.setItem(storageKey, JSON.stringify(storageValue));
                break;
            } catch (error) {
                if (attempt == 1) console.error(error);
                if (this.isQuotaExceededError(error)) {
                    console.log("Cache full, clearing cache")
                    this.removeAll();
                }
            }
        }
    }

    /**
   * Removed an object from cache
   * @param key
   */
    public remove(key: string): void {
        localStorage.removeItem(this.toStorageKey(key));
    }

    /**
   * Remove all objects from cache
   */
    public removeAll(): void {
        this.getAllKeys().forEach(key => {
            this.remove(key);
        });
        localStorage.removeItem("events:latest") // Remove legacy cache name for infinite-scroll calendar
    }

    /**
   * Remove all objects from cache with a certain tag
   */
    public removeAllWithTag(tag: string): void {
        this.getAllKeys()
            .filter(key => this.hasTag(key, tag))
            .forEach(key => {
                this.remove(key);
            });
    }

    /**
   * Retrieves an object from cache
   * @param key
   */
    public get<T>(key: string): T {
    // Start with value = null
        let value: T = null;
        // Get the item stored with the key and parse it
        try {
            const storageValue = JSON.parse(
                localStorage.getItem(this.toStorageKey(key))
            );
            if (storageValue) {
                if (this.validateStorageValue(storageValue)) {
                    value = storageValue.value;
                } else {
                    this.remove(key);
                }
            }
        } catch (error) {
            console.error(error);
        }
        return value;
    }

    /**
   * Same as regular 'get' but returns the CacheOptions instead of the data object
   * @param key
   */
    public getOptions(key: string): CacheOptions {
    // Start with value = null
        let value = null;
        // Get the item stored with the key and parse it
        const storageValue = JSON.parse(
            localStorage.getItem(this.toStorageKey(key))
        );
        if (storageValue) {
            if (this.validateStorageValue(storageValue)) {
                value = storageValue.options;
            } else {
                this.remove(key);
            }
        }
        return value;
    }

    /**
   * Checks whether a cache item with given key is tagged with given tag
   * @param key
   * @param tag
   */
    private hasTag(key: string, tag: string): boolean {
        const options = this.getOptions(key);
        if (!options || !options.tags) {
            return false;
        }
        const tags: string[] = options.tags;
        return tags !== undefined && tags.indexOf(tag) > -1;
    }

    /**
   * Get the keys from all items currently in cache
   */
    private getAllKeys(): string[] {
        return Object.keys(localStorage.valueOf())
            .filter(key => key.startsWith(this.prefix))
            .map(key => this.fromStorageKey(key));
    }

    /**
   * Takes an key and makes it usable for storage
   * @param key
   */
    private toStorageKey(key: string): string {
        return this.prefix + '-' + key;
    }

    /**
   * Takes an storage key and returns its original form
   * @param key
   */
    private fromStorageKey(key: string) {
        return key.replace(this.prefix + '-', '');
    }

    /**
   * Takes an object to be cached and adds the caching metadata
   * @param value
   * @param options
   */
    private toStorageValue(value: unknown, options: CacheOptions): StorageObject {
        return {
            value,
            options: this.toStorageOptions(options)
        };
    }

    /**
   * Takes a possibly incomplete options object and completes it
   * @param options
   */
    private toStorageOptions(options: CacheOptions): CacheOptions {
        const storageOptions: CacheOptions = {};
        // Was the expires option explicitly given? Than use that.
        // Otherwise calculate it using the maxAge options
        storageOptions.expires = options.expires
            ? options.expires
            : options.maxAge
                ? Date.now() + options.maxAge * 1000
                : this.defaultOptions.expires;
        storageOptions.maxAge = options.maxAge
            ? options.maxAge
            : this.defaultOptions.maxAge;
        storageOptions.tags = options.tags;
        return storageOptions;
    }

    /**
   * Takes an cached object and checks whether it has expired yet
   * @param value
   */
    private validateStorageValue(value: StorageObject): boolean {
    // Is there an expires option and is it larger than the current timestamp?
        return !!value.options.expires && value.options.expires > Date.now();
    }

    /**
   * Checks whether content with this key is already being retrieved.
   * This prevents multiple subscriptions to an observable when the cache is called
   * multiple times at the same time.
   * This was built to prevent multiple API calls being made when e.g. userInfo was not
   * in cache yet and it was requested at multiple places in the app at once.
   * @param key
   */
    private isAlreadyInProgress(key: string): boolean {
        return this.inProgress.indexOf(key) > -1;
    }

    /**
     * Determine whether the error indicates that localStorage is full
     * @param err Error object
     * @returns boolean
     */
    private isQuotaExceededError(err: unknown): boolean {
        return (
            err instanceof DOMException &&
            // everything except Firefox
            (err.code === 22 ||
            // Firefox
            err.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            err.name === "QuotaExceededError" ||
            // Firefox
            err.name === "NS_ERROR_DOM_QUOTA_REACHED")
        );
    }
}
